@font-face {
  font-family: 'Century Gothic';
  src: local('Century Gothic'), url(./assets/fonts/Century-Gothic.ttf) format('truetype');
}

@font-face {
  font-family: 'Sen ExtraBold';
  src: local('Sen ExtraBold'), url(./assets/fonts/Sen-ExtraBold.ttf) format('truetype');
}

body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#videoText {
  /*position: absolute;*/
  /*inset: 0;*/
  /*display: grid;*/
  /*place-items: center;*/
  /*background-color: #000;*/
  /*color: #fff;*/
  /*font-weight: 900;*/
  /*font-size: 100px;*/
  /*font-family: sans-serif;*/
  /*!* Mix Blend Mode does the all magic *!*/
  /*mix-blend-mode: multiply;*/
  /*user-select: none;*/
  text-transform: uppercase;
  /*background-image: linear-gradient(to right, #1B5E20, #558B2F, #558B2F, #1B5E20);*/
  background-image: linear-gradient(to right, #000000, #424242, #000000, #000000);
  /*background-image: linear-gradient(to right, #012b09, #01480f, #01480f, #012b09);*/
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: -100%;

  animation: animatedText 5s infinite alternate-reverse;
}

@keyframes animatedText{
  to{
    background-position: 100%;
  }
}

#email {
  text-decoration: none;
  transition: color 0.5s ease;
}

#laurelsSection {
  position: relative;
  height: 35vh;
  display: flex;
  justify-content: center;
  alignItems: center;
}

@media screen and (max-width: 680px) {
  #laurelsSection {
    height: 10vh;
  }
  #videoText {
    font-size: 18vw !important;
  }
}

#email:hover {
  color: #146e2d;
}

